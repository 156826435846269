import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import * as style from "./styles.module.scss"



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      className={style.tabpanelcon}
      {...other}
    >
      {value === index && (
        <div p={3}>
         {children}
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
      },
}));

export default function FullWidthTabs() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const StyledTabs = withStyles({
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: '#ffffff',
      boxShadow:'0px 4px 10px rgba(0, 0, 0, 0.25)',
      zIndex:'1',
      borderRadius:'50px',
      height:'40px',
      bottom:'12px',
      margin:'auto 0',
      '& > span': {
        maxWidth: 0,
        width: '100%',
        backgroundColor: '#3d7fe8',
      },
    },
  })((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
  
  const StyledTab = withStyles((theme) => ({
    root: {
      textTransform: 'none',
      color: '#000',
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      zIndex:'2',
      '&:focus': {
        opacity: 1,
      },
    },
  }))((props) => <Tab disableRipple {...props} />);

  return (
    <div className={style.tabcontainer}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="#000"
          variant="scrollable"
          centered={true}
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          className={style.autotabs}
        >
          <StyledTab label="User Research" {...a11yProps(0)} />
          <StyledTab label="Validation" {...a11yProps(1)} />
          <StyledTab label="Implementation" {...a11yProps(2)} />


          
        </StyledTabs>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
        className={style.backtransparent}
      >
        <TabPanel className={style.tabpanelcontent} value={value} index={0} dir={theme.direction}>
        <iframe className={style.iframe} width="100%" height="700" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FQhpdBrXV0pt13RmFGUAI8V%2Fprojects%3Fnode-id%3D2%253A2" allowfullscreen></iframe>
        </TabPanel>
        <TabPanel className={style.tabpanelcontent} value={value} index={1} dir={theme.direction}>
        <iframe className={style.iframe} width="100%" height="700" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FQhpdBrXV0pt13RmFGUAI8V%2Fprojects%3Fnode-id%3D1300%253A3" allowfullscreen>></iframe>        </TabPanel>
        <TabPanel className={style.tabpanelcontent} value={value} index={2} dir={theme.direction}>
        <iframe className={style.iframe} width="100%" height="700" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FQhpdBrXV0pt13RmFGUAI8V%2Fprojects%3Fnode-id%3D227%253A35" allowfullscreen></iframe>        
        </TabPanel>
       

        
      </SwipeableViews>
    </div>
  );
}
