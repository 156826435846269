import React from 'react';
import Bluebanner from "../../../components/bluebanner"
import Homeheader from "../../../components/home_header"
import Fullnav from "../../../components/fullnav"
import * as style from "./styles.module.scss"
import Container from '@material-ui/core/Container'
import Drawer from '@material-ui/core/Drawer';
import Slidepanel from "../../../components/slidepanel"
import { Button, CardContent } from '@material-ui/core';
import mainlogosmall from "../../../images/logo_port_slim_small.svg"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from "gatsby"
import CaseHeader from '../../../components/case_header';
import caseimg from "../../../images/projects/xm.png"
import xmlogo from "../../../images/projects/xm_logo.svg"

import caseimgfull from "../../../images/projects/xmfull.png"
import Sectionheaderdark from "../../../components/typography/section_headers/dark"
import Card from '@material-ui/core/Card';
import Cardcontent from '@material-ui/core/CardContent'
import classnames from 'classnames'

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AlbumIcon from '@material-ui/icons/Album';
import classNames from 'classnames'
import Sectionheaderlight from '../../../components/typography/section_headers/light';
import Remotetabs from "./tabs"
import Footer from '../../../components/footer';
import { StaticImage } from "gatsby-plugin-image"
import xmresearch from "../../../images/projects/xm_useresearch.svg"
import xmresearchmob from "../../../images/projects/xm_useresearch_mob.svg"
import xmmocks from "../../../images/projects/xm_mocks.svg"
import xmmocksmob from "../../../images/projects/xm_mocks_mob.svg"
import xmimp from "../../../images/projects/xm_implementation.svg"
import xmimpmob from "../../../images/projects/xm_implementation_mob.svg"
import Video from '../../../components/video';


import Gridify from '../../../components/gridify';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Style } from '@material-ui/icons';
import xminvision from "../../../images/projects/xminvision.gif"
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import { getUser, isLoggedIn } from "../../../services/auth"
import Layout from "../../../components/layout"
import Login from "../../../components/login"
import NavBar from "../../../components/nav-bar"








const Remote = () => {
    return (
        <>
        {
        // isLoggedIn() ? (
        <div >

            <Container className={style.menucontainer} maxWidth={false} disableGutters={true}>
                <Container>
                    <div className={style.onlymobile}>
                        <Fullnav ></Fullnav>
                    </div>
                    <div id="white" className={style.simplenav}>
                        <div className={style.adjuster}>
                            <div className={style.backlink} >
                                <Link to="/"> <ArrowBackIosIcon />
                                    <img src={mainlogosmall} />
                                </Link><span>/</span><span><Link className={style.backto} to="/#past">All Projects</Link></span><span>/</span><span className={style.currentlink}>Developer Experience</span>
                            </div>
                        </div>
                        <div className={style.logout}>
                        <NavBar/>
                        
                        <Slidepanel></Slidepanel>
                        </div>

                    </div>
                </Container>
            </Container >
            <CaseHeader label="190+ Developer Products, 800+ Documents, 23 scattered sites - All came together to become a unified portal for 17,000 + Developers. "
                description="Take a deeper look at how we built this for a retail giant and enhanced their Developer Experience."
                // high1="Increased adoption by more than 10K+ monthly developer visits"
                // high2="150+ products and 500+ docs and templates hosted and managed "
                // high3="Unified 25+ scattered sites into one source of truth"
                gradientdark
                img={xmlogo}
            >            <div className={style.spacer64}></div>
            </CaseHeader>

            <div className={classnames(style.separator, style.darkbg)}>
                <Container><hr className={style.hrdotted}></hr>
                </Container>
            </div>
            <div className={classnames(style.darkbg, style.pf_nopad_TB)}>
                <Container>
                    <div className={style.pitchimagegrid}>
                        <div className={style.imgholder}>
                            <StaticImage className={classNames()}
                                alt="XM"
                                src="../../../images/projects/xmfull1440.jpg"
                                placeholder="blurred"
                            />
                        </div>
                        <div className={classnames(style.darkbg)}>
                            <Container>
                                <div className={style.pitch}>

                                    <p>If you are someone who has worked with developers in some capacity, you might have come across phrases like
                                </p>
                                </div>
                            </Container>
                            <div className={style.pitchquuotes}>

                                <p>"It's deployed, waiting for the build to finish"</p>


                                <p>"Dev setup alone takes up the entire sprint"</p>
                                <p>"No more changes after this push to prod"</p>

                                <p>"Not sure why but the build has failed"</p>

                            </div>
                            <Container>
                                <div className={style.pitch}>
                                    <p>
                                        These are some of the things I have come across in my professional life designing apps. To be honest I used to judge each and every phrase thinking how hard is it to do this - until I worked on this project and learned about the loops developers have to go through to deploy simple changes.
                            </p>

                                    <p>
                                        It's only recently that companies have thought about the developer experience addressing challenges like standard documentation, universal templates, patterns and guidelines, App Starter Kits etc. </p>

                                    <p>The client in this project is one of those who are actively changing their approach towards Developer Experience and hoping to provide a better platform to address these challenges.
                            </p>

                                </div>
                            </Container>

                        </div>
                    </div>
                </Container>
            </div>
            <div className={classnames(style.separator, style.darkbg)}>
                <Container><hr className={style.hrdotted}></hr>
                </Container>
            </div>


            <Container maxWidth={false} className={style.darkbg}>
                <Container >
                    <Sectionheaderdark label=" My Role" className={style.pf_all_white_text} ></Sectionheaderdark>
                    <div className={classnames(style.pf_two_block_grid, style.pf_all_white_text)}>
                        <div>
                            <ul >
                                <li className={style.ligrid}>
                                    <CheckCircleIcon className={style.blue} /><h2>Lead</h2>
                                </li>
                                <li>
                                    <p>
                                        Lead a 2 member design team to
                                        evaluate, identify and create interactive mocks
                                        that capture the project vision and end user goals.
                                </p>
                                </li>
                            </ul>
                        </div>
                        <div >
                            <ul >
                                <li className={style.ligrid}>
                                    <CheckCircleIcon className={style.blue} /><h2>Plan & Facilitate</h2>
                                </li>
                                <li>
                                    <p>
                                        Validate mocks with users, stakeholders and the development team and assist Product managers in roadmap planning.
                                </p>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <ul >
                                <li className={style.ligrid}>
                                    <CheckCircleIcon className={style.blue} /><h2>Implement</h2>
                                </li>
                                <li>
                                    <p>
                                        Ensure flawless implementation of the design through continuos collaboration with the development team. Strategize Component
                                        libraries for reusability.
                                </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={classnames(style.containerheads, style.pf_all_white_text, style.spacer64)}>
                        <p>To simply put I was responsible for understanding the problems and come up with a solution along with Product Managers, Engineering leads and execs. </p>
                        <p>I lead a team of 2 amazing designers who gracefully indulged in several of my ad-hoc brainstorm sessions, iterations - culminating into a full blown portal that was received extremely well and even resulted in promotions for key contributors! </p>
                    </div>
                </Container>
            </Container>


            <Container maxWidth={false} className={classnames(style.pf_container_pad)}>
                <div className={style.spacer32}></div>
                <Sectionheaderlight caption="STEP 1" label="Discovery" />
                <div className={style.spacer16}></div>

                <Container >
                    <div className={style.containerheads}>
                        <p>Going into the project we were not sure where to start since the information conveyed was not enough. We required more to start assessing and planning our way through and formulate the business goals, user goals etc. Basically we needed data to begin. For this, we started with interviewing the key players - Stakeholders, Users & Department Leads.</p>
                    </div>
                </Container>
                <Gridify>
                    <div className={style.pf_gridify}>
                        <div className={style.pf_mainCardDark}>

                            <div>
                                <div>
                                    <div>
                                        <div className={style.cardiconsgrid}>
                                            <h1>Stakeholder Interviews</h1>
                                            <div className={style.pf_cardicons_nopborder}>
                                                <ArrowForwardIcon />
                                            </div>
                                        </div>

                                        <p>We met with the stakeholders to understand exactly how they perceive the problem, get insights on what would be the right approach and who would can give us more info.</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.pf_gridify}>
                        <div className={style.pf_mainCard}>

                            <div>
                                <div>
                                    <div>
                                        <h2 className={style.cardquuotes}>"We need to unify all the scattered sites and provide one platform. That needs to be the source of truth. All department leads need to get on the same page."</h2>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.pf_gridify}>
                        <div className={style.pf_mainCard}>

                            <div>
                                <div>
                                    <div>
                                        <h2 className={style.cardquuotes}>"The engineers who are just joining, take a lot of time to ramp up because of so many different sources of information. We have an opportunity to reduce the onboarding time."</h2>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.pf_gridify}>
                        <div className={style.pf_mainCardDark}>

                            <div>
                                <div>
                                    <div>
                                        <div className={style.cardiconsgrid}>
                                            <h1>Department Leads</h1>
                                            <div className={style.pf_cardicons_nopborder}>
                                                <ArrowForwardIcon />
                                            </div>
                                        </div>

                                        <p>Department Leads were the key in understanding the magnitude of the project since the vision was to have one unified platform. Talking to the leads helped us uncover their scattered workflow, support tickets & document management</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.pf_gridify}>
                        <div className={style.pf_mainCard}>

                            <div>
                                <div>
                                    <div>
                                        <h2 className={style.cardquuotes}>"My team spends a lot of time answering the same questions about which document is the standard?, is there a new version of the patterns? can I use this product in my project? "</h2>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.pf_gridify}>
                        <div className={style.pf_mainCard}>

                            <div>
                                <div>
                                    <div>
                                        <h2 className={style.cardquuotes}>"There is a huge disconnect between the tools and processes throughout our development lifecycle. We need to integrate and automate as much as possible"</h2>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.pf_gridify}>
                        <div className={style.pf_mainCardDark}>

                            <div>
                                <div>
                                    <div>
                                        <div className={style.cardiconsgrid}>
                                            <h1>Users</h1>
                                            <div className={style.pf_cardicons_nopborder}>
                                                <ArrowForwardIcon />
                                            </div>
                                        </div>

                                        <p>Our users are mostly developers with varying experience within the organization. With the help of the leads we selected developers from different departments, even including some of the new joiners going through onboarding. In total we interviewed about 9 developers to get a good handle on our users mental model. </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.pf_gridify}>
                        <div className={style.pf_mainCard}>

                            <div>
                                <div>
                                    <div>
                                        <h2 className={style.cardquuotes}>"By the time I find a guideline document and start implementing those steps, someone sends me a new version. Even the collaborators usually are not aware of those new updates"</h2>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.pf_gridify}>
                        <div className={style.pf_mainCard}>

                            <div>
                                <div>
                                    <div>
                                        <h2 className={style.cardquuotes}>"It would be good to have one place that is standardized so that I don't have to do more work to find something. I just want to go there and start consuming the information"</h2>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.pf_gridify}>
                        <div className={style.pf_mainCard}>

                            <div>
                                <div>
                                    <div>
                                        <h2 className={style.cardquuotes}>"I have a list of steps noted down for deployment. It usually takes a while to finish all the additional steps apart from finishing the coding. It becomes difficult to explain why deployment is taking time"</h2>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                </Gridify>

            </Container>
            <Container>
                <div className={style.separator}>
                    <hr></hr>
                </div>
            </Container>
            <Container maxWidth={false} className={style.pf_container_pad}>
                <Sectionheaderlight caption="STEP 2" label="User Personas" />
                <Container>
                    <div className={style.containerheads}>
                        <p>After getting all the possible information, we started to analyze and identify the themes in our data. We worked alongside the Product Manager to make sure we don't digress too much. Our initial analysis resulted in a set of themes which we later distilled to finalize three major types of users and defined their needs and pain points.</p>
                    </div>
                </Container>
                <Gridify number={true}>
                    <div className={style.pf_gridify}>
                        <div className={style.pf_mainCardDark}>

                            <div>
                                <div>
                                    <div>
                                        <div className={style.cardiconsgrid}>
                                            <h1>Consumer </h1>
                                            <div className={style.pf_cardicons_nopborder}>
                                                <ArrowForwardIcon />
                                            </div>
                                        </div>

                                        <p>Developer who is looking to consume resources for learning and software development tooling</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.pf_gridify}>
                        <div className={style.pf_mainCard}>

                            <div>
                                <div>
                                    <div className={style.cardquuotes}>
                                        <h2>Challenges</h2>
                                        <p><strong>Content Discovery & Learning: </strong>
                                        No single source of truth for developer resources</p>
                                        <p><strong>Application Setup & Approvals: </strong>
                                       Too many scattered portals with separate onboarding process</p>
                                        <p> <strong>Deployment Management & Performance: </strong>Lack of integration and automation between tools & processes throughout the development lifecycle</p>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={style.pf_gridify}>
                        <div className={style.pf_mainCardDark}>

                            <div>
                                <div>
                                    <div>
                                        <div className={style.cardiconsgrid}>
                                            <h1>Contributor </h1>
                                            <div className={style.pf_cardicons_nopborder}>
                                                <ArrowForwardIcon />
                                            </div>
                                        </div>

                                        <p>Developer or Product Manager who would like to share content, resources and experiences.</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.pf_gridify}>
                        <div className={style.pf_mainCard}>

                            <div>
                                <div>
                                    <div className={style.cardquuotes}>
                                        <h2>Challenges</h2>
                                        <p><strong>Tool Integration Process: </strong>
                                        Difficult deeper-level integrations into central platform due to varying design patterns, UX flows, and technology stacks used for existing tool-sets</p>
                                        <p><strong>Standardized Process & Tooling: </strong>
                                       Uncertainty of where to contribute as a result of lacking guidelines, process, and tooling for contributing product documentation, APIs, Starter Kit templates, code samples, and insights.</p>


                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.pf_gridify}>
                        <div className={style.pf_mainCardDark}>

                            <div>
                                <div>
                                    <div>
                                        <div className={style.cardiconsgrid}>
                                            <h1>Moderator </h1>
                                            <div className={style.pf_cardicons_nopborder}>
                                                <ArrowForwardIcon />
                                            </div>
                                        </div>

                                        <p>Developer or department lead who is responsible for the standardization of the recommended guidelines, templates, architectural designs, training and onboarding documents.</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.pf_gridify}>
                        <div className={style.pf_mainCard}>

                            <div>
                                <div>
                                    <div className={style.cardquuotes}>
                                        <h2>Challenges</h2>
                                        <p><strong>Unscalable Review Processes: </strong>
                                        Existing process requires manual steps and human reviews/approvals.</p>
                                        <p><strong>Content Quality: </strong>
                                       Difficult to enforce guidelines, starter kits and other docs quality checks/gates </p>
                                        <p><strong>Communication Channels: </strong>
                                       Uncertainty about support process due to plethora of communication channels.
                                       </p>


                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>



                </Gridify>
            </Container>
            <Container>
                <div className={style.separator}>
                    <hr></hr>
                </div>
            </Container>
            <Container className={style.pf_container_pad}>
                <Sectionheaderlight caption="Step 3" label="Journey Map" />
                <Container>
                    <div className={style.containerheads}>
                        <p>After deciding the key user personas we started to explore the current user journey through the organization. We identified the activities, touchpoints and departments related to the stages of development lifecycle. Doing this, allowed us to figure out opportunities to improve and also gave us an overview of several integrations that need to happen within the several scattered sites across the organization.</p>
                    </div>
                </Container>
                <div className={style.spacer32}></div>
                <div>
                    <StaticImage className={classNames()}
                        alt="XM"
                        src="../../../images/projects/xmjourney.png"
                        placeholder="blurred"
                    />
                    <p className={style.imginfo}>Note: Touchpoints blurred on purpose</p>
                </div>
            </Container>
            <Container>
                <div className={style.separator}>
                    <hr></hr>
                </div>
            </Container>
            <Container className={style.pf_container_pad}>
                <Sectionheaderlight caption="Step 4" label="The Big Picture" />
                <Container>
                    <div className={style.containerheads}>
                        <p>Now that we got a good view of the landscape and where we were heading, we wanted to summarize our approach in a few sentences. We came up with a high level, short descriptions for the Business Goals + User goals and sought to get sign-off from the key stakeholders. We also decided together as a team on a few target goals that would set us on the right path and also help us measure our success. </p>
                    </div>
                </Container>

                <Card className={classNames(style.casecard, style.highcard, style.highggrid)}>
                    <div>
                        <div>
                            <h2>Business Goals</h2>
                            <p>
                                Build a unified portal that serves all developers with the resources and functionalities that enables them to accelerate, optimize, and ship quality code.
                            </p>
                        </div>
                        <div>
                            <h2>User Goals</h2>
                            <p>Access a unified portal that simplifies finding useful resources like recommended products, patterns, templates, guidelines etc. Reduce app building time by utilizing available Starter kits and App templates that are fully automated, integrated and tested to work seamlessly with the required compliances, checks and monitoring.
                            </p>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h2>Target Goals</h2>
                            <ul className={style.goalslist}>
                                <li className={style.ligrid}>
                                    <AlbumIcon className={style.blue} /> <span><strong>10K </strong>Monthly Active Users</span>
                                </li>
                                <li className={style.ligrid}>
                                    <AlbumIcon className={style.blue} /> <span><strong>1 week </strong>of onboarding time</span>
                                </li>
                                <li className={style.ligrid}>
                                    <AlbumIcon className={style.blue} /><span> One source of truth for all <strong>products, guides & patterns</strong></span>
                                </li>
                                <li className={style.ligrid}>
                                    <AlbumIcon className={style.blue} /> <span>Less than <strong>5 mins</strong> App build time</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                </Card>
            </Container>
            <Container>
                <div className={style.separator}>
                    <hr></hr>
                </div>
            </Container>
            <Container className={style.pf_container_pad}>
                <Sectionheaderlight caption="Step 5" label="Brainstorming & User flows">
                    <Container>
                        <div className={style.containerheads}>
                            <p>After getting an official sign-off on our approach and goals we started brainstorming on the user flows and dependencies that needs to be taken into account. We actively shared the flow with the department leads to get timely feedback. The challenge here was to get everyone on the same page. With some adjustments to the flow and the timeline we were able to freeze on 1 unified user flow</p>


                        </div>
                    </Container>
                </Sectionheaderlight>
                <div>
                    <StaticImage className={classNames()}
                        alt="XM"
                        src="../../../images/projects/xmflows.png"
                        placeholder="blurred"
                    />
                    <p className={style.imginfo}>Note: Blurred on purpose</p>
                </div>

            </Container>
            <Container>
                <div className={style.separator}>
                    <hr></hr>
                </div>
            </Container>
            <Container className={style.pf_container_pad}>
                <Sectionheaderlight caption="Step 6" label="Content Assessment">
                    <Container>
                        <div className={style.containerheads}>
                            <p>One of the biggest challenge we faced was to handle the huge data that was part of the content expected to be comsumed by the users. Since design will drastically be impacted by the available data and also the future data, we sought to get an estimation on some of the important areas that will be heavy in data. Several ocassions we had to visualize the hierarchy to get a better understanding of the data so that we could come up with a re-usable structure</p>
                            <p>The MVP that we decided on was targeted to include just the desktop users in the first phase. We compared and analyzed the data estimations with respect to the web experience. Our efforts involved speaking to Engineering Leads, Front-end experts to provide an overall idea of the page loading time without compromising the User Experience</p>


                        </div>
                    </Container>
                </Sectionheaderlight>
                <div>
                    <StaticImage className={classNames()}
                        alt="XM"
                        src="../../../images/projects/xmdata.png"
                        placeholder="blurred"
                    />
                    <p className={style.imginfo}>Visual representation of the levels of data that needed to be comsumed by the user. Different colors represent the different hierarchy levels. Ths approach helped us structure and also set limitations on the magnitude of the data to be showcased in one go.</p>
                </div>

            </Container>
            <Container>
                <div className={style.separator}>
                    <hr></hr>
                </div>
            </Container>
            <Container className={style.pf_container_pad}>
                <Sectionheaderlight caption="Step 7" label="Learning through Iterations">
                    <div className={style.spacer32}></div>
                    <div className={style.spacer32}></div>
                    <div className={style.contentgrid_center}>
                        <div>
                            <div className={style.pf_container_pad, style.embedframegif}>
                                <img src={xminvision} />
                            </div>

                            <p className={style.imginfo}>Iterations uploaded to invision projects</p>
                        </div>
                        <Container>
                            <div>
                                <p>After deciding the features, content and MVP goals we went ahead with medium-fidelity
                        interactive mocks with clearly defined scenarios. This was an iterative process. I mean really iterative process. </p>

                                <p>Everytime we did testing we got to know about some other important aspect, feature or area that was left out. The quick testing allowed us to reach out to different departments and the mocks definitely came in handy to set the context and learn more.</p>

                                <p>After atleast 6 different versions we finally arrived at the one that was signed-off by everyone we tested, along with excitement to see the finished product </p>
                            </div>
                        </Container>


                    </div>
                </Sectionheaderlight>

                <div className={style.spacer32}></div>
            </Container>

            {/* <Container className={style.pf_container_pad}>
                <Sectionheaderlight caption="Step 3" label="Implementation" >
                    <div className={style.contentgrid}>
                        <p>Once the feedback got incorporated we began with the visual mocks based on the company brand guidelines.
                        We started the conversation related to Component Libraries so the engineering gets enough time to setup
                        before the first set of visual mocks are ready to Implement.
                    </p>
                        <p>Each sprint we carved time for Visual QA to make sure there is no inconsistency with implmentation and
                        interaction patterns. Progress was showcased every sprint to the stakeholders for more feedback and design
                        work-arounds because of technical challenges.  </p>

                    </div>
                </Sectionheaderlight>
                <div className={style.pf_container_pad, style.embedframe}>
                    <img src={xmimp} />
                </div>
                <div className={style.pf_container_pad, style.embedframemob}>
                    <img src={xmimpmob} />
                </div>
            </Container> */}
            <Container className={classNames(style.greyblock, style.pf_container_pad)} maxWidth={false} disableGutters={true}>



                <Container className={style.pf_container_pad_top}>
                    <div className={style.spacer32}></div>

                    <Sectionheaderlight caption="Showcasing" label="Mocks and Interactions">

                    </Sectionheaderlight>
                    <div className={style.spacer32}></div>

                </Container>
                {/* <div className={style.mockpanel}>

                   </div> */}

                <Container>
                    <div className={style.videocontent}>

                        <div className={style.containerheads_mock}>
                            <h3>Product Catalog </h3>
                            <p>
                                One of the feature that everyone requested was the ability to browse through products that are available inside the org with recommendations and compliance tags. We built a robust product catalog that houses around 200 products categorized into familiar buckets. 
                            </p>
                          
                        </div>
                        <div className={style.containerheads_mock}>
                            
                        <p>Users can click on the product to view additional info like - Links to docs, P1 support, cloud availability etc without leaving the page with the help of collapsible side panels. The filter menu above the product listings allows users to filter based on several parameters.</p>
                          
                        </div>
                        
                    </div>
                    <div className={style.spacer32}></div>

                    <div className={style.videopanel}>
                        <Video video={"https://youtu.be/9BQFLsLTLR0"} />
                    </div>
                </Container>






                <Container>
                    <div className={style.spacer32}></div>
                    <div className={style.separator}>
                        <hr></hr>
                    </div>
                    <div className={style.spacer32}></div>

                </Container>
                <Container>
                    <div className={style.videocontent}>

                        <div className={style.containerheads_mock}>
                            <h3>Search Experience </h3>
                            <div >
                                <div>
                                    <p>
                                        One thing that is extremely important for a unified portal to enable easy discovery is a robust search feature. We made sure search can be performed from any screen of the entire app. The results are based on ratings derived from custom algorithm.
                            </p>
                                    <p>The filters on the left helps developers further narrow down on the desired results. To ensure transparency on the freshness of the content, we decided to provide the updated date to keep a check on stale data. </p>

                                </div>
                                <div>
                                    <p>
                                        To enahnce the overall discoverability we made sure to include important categories such as products, APIs and Starter Kits as an option in the main search experience. Whenever the result include these they popup at the top.
                            </p>
                                    <p>
                                        Another important feature we wanted to provide was the Stackoverflow Q&A. Along with other things Q&A helps developers easily get to issues that have been already resolved, thus saving a lot of time & effort.
                            </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.spacer32}></div>

                    <div className={style.videopanel}>
                        <Video video={"https://youtu.be/RI0FbssO1tQ"} />
                    </div>
                </Container>




            </Container>
            <Container maxWidth={false} className={style.pf_nopad_LR} >
                <div className={style.darkbg}>
                    <Container >
                       

                            <div className={style.calloutgrid}>
                                <div className={classnames(style.containerheads, style.callout)}>
                                    <p>My portolfio is a work in progress. I will continue updating whenever I find some time.
                                </p>
                                    <h3>Meanwhile, please reach out to me if you are interested to know more. I would be happy to walk you through more details!</h3>

                                </div>
                                <div><InsertEmoticonIcon /></div>
                               

                            </div>

                        
                        <div className={classnames(style.separator, style.darkbg)}>
                            <Container><hr className={style.hrdotted}></hr>
                            </Container>
                        </div>



                    </Container>
                </div>
            </Container>
            <Footer />


        </div>
        // ): (
        //     <>
        //       <Login></Login>
        //     </>
        //   )
        }
        </>
    )
}


export default Remote


