import * as React from "react"
import Container from '@material-ui/core/Container'
import * as style from "./styles.module.scss"
import classNames from 'classnames'
import ReactPlayer from 'react-player'
import imgback from "../../images/videoframe.png"
import { StaticImage } from "gatsby-plugin-image"





const Video = ({video}) => {
    return (
      <Container>
       {/* <div className={style.pf_videoframe}>
         <div className={style.videoadjuster}>
         <div className={style.pf_video}>
           <ReactPlayer controls ={true}width={"100%"} height={"100%"} playing={true} loop={true} url={video}/>
         </div>
         </div>
       </div> */}
       <div className={style.videorelative}>
         <div className={style.imgabsolute}>
          <img src={imgback}/>
         </div>
         <div className={style.floatingvideo}>
         <ReactPlayer controls ={true}width={"100%"} height={"100%"} playing={true} loop={true} url={video}/>

         </div>
       </div>

      </Container>
      
      
      
    )
}


export default Video