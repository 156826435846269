import * as React from "react"
import Container from '@material-ui/core/Container'
import * as style from "./styles.module.scss"
import classNames from 'classnames'






const Gridify = ({ children, number }) => {
  return (
    <Container maxWidth="false" className={style.pf_nopad_LR}>
      {number
      ?<div className={classNames(style.pf_symetric_grid, style.pf_block_pads_top, style.pf_symetric_grid_3)}>
      {children}


    </div>
      :<div className={classNames(style.pf_symetric_grid, style.pf_block_pads_top, style.pf_symetric_grid_2)}>
      {children}


    </div>
      }
      

    </Container>


  )
}


export default Gridify